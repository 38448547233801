.modal-30w {
  max-width: none !important;
  width: 30% !important;
}

.modal-50w {
  max-width: none !important;
  width: 50% !important;
}

.modal-55w {
  max-width: none !important;
  width: 55% !important;
}

.modal-60w {
  max-width: none !important;
  width: 60% !important;
}

.modal-65w {
  max-width: none !important;
  width: 65% !important;
}

/* 화면 너비가 1700px 이하일 때 너비 조절 */
@media screen and (max-width: 1700px) {
  .modal-65w {
    width: 97% !important; /* 원하는 크기로 조절 */
  }
}

.modal-70w {
  max-width: none !important;
  width: 70% !important;
}

/* 화면 너비가 1700px 이하일 때 너비 조절 */
@media screen and (max-width: 1700px) {
  .modal-70w {
    width: 97% !important; /* 원하는 크기로 조절 */
  }
}

.modal-75w {
  max-width: none !important;
  width: 75% !important;
}

@media screen and (max-width: 1700px) {
  .modal-75w {
    width: 97% !important; /* 원하는 크기로 조절 */
  }
}

.modal-80w {
  max-width: none !important;
  width: 80% !important;
}

@media screen and (max-width: 1700px) {
  .modal-80w {
    width: 97% !important; /* 원하는 크기로 조절 */
  }
}

.modal-85w {
  max-width: none !important;
  width: 85% !important;
}

@media screen and (max-width: 1700px) {
  .modal-85w {
    width: 97% !important; /* 원하는 크기로 조절 */
  }
}

.modal-90w {
  max-width: none !important;
  width: 90% !important;
}

@media screen and (max-width: 1700px) {
  .modal-90w {
    width: 97% !important; /* 원하는 크기로 조절 */
  }
}


.mx-offcanvas {
  margin-left: 22% !important;
  margin-right: 22% !important;
}

/* 화면 너비가 1700px 이하일 때 너비 조절 */
@media screen and (max-width: 1700px) {
  .mx-offcanvas {
    margin-left: 18% !important;
    margin-right: 18% !important;
  }
}

/* 화면 너비가 1700px 이하일 때 너비 조절 */
@media screen and (max-width: 1540px) {
  .mx-offcanvas {
    margin-left: 15% !important;
    margin-right: 15% !important;
  }
}

/* 화면 너비가 1700px 이하일 때 너비 조절 */
@media screen and (max-width: 768px) {
  .mx-offcanvas {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
}


/* 화면 너비가 1700px 이하일 때 너비 조절 */
@media screen and (max-width: 1700px) {
  .modal-70w {
    width: 97% !important; /* 원하는 크기로 조절 */
  }
}

@media screen and (min-width: 1700px) {
  .d-custom-block {
    display: block !important;
  }
}

